/* @noflow */
/* eslint-disable react/jsx-filename-extension */
import React, {FC} from 'react';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

import {chromeExtensionLink} from '../../../constants';
// @ts-expect-error
import Steps from '../../Steps';
// @ts-expect-error
import TermsOfService from '../../TermsOfService';
// @ts-expect-error
import FooterLight from '../../FooterLight';
import MapquestLogo from '../../../assets/svg/mapquest-logo-black.svg';
// @ts-expect-error
import currentBrowserName from '../../../util/detectBrowser';
// @ts-expect-error
import Modal from '../../Modal';
import Button from '../../Button';
import {ExperimentProps} from '../type';

const IndexBlueCta: FC<ExperimentProps> = ({onCtaClick}) => {
    const site = useStaticQuery(graphql`
        query IndexBlueCta {
            map: file(relativePath: {eq: "mapNewYork.png"}) {
                childImageSharp {
                    fixed(width: 1200, quality: 30) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);
    return (
        <>
            <Img
                fixed={site.map.childImageSharp.fixed}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    zIndex: '0'
                }}
                imgStyle={{
                    zIndex: '0',
                    position: 'relative'
                }}
            />
            <Modal maxWidth="750px" innerMargin="10%" marginTop="0px">
                <div
                    css={css`
                        margin: 1rem 0 2.5rem;
                    `}
                >
                    <MapquestLogo />
                </div>
                <h1
                    css={css`
                        font-size: 2rem;
                        font-weight: 600;
                        line-height: 2.75rem;
                        letter-spacing: 0.05rem;
                        @media (max-width: 600px) {
                            font-size: 1.5rem;
                            line-height: normal;
                        }
                    `}
                >
                    Quick step-by-step directions
                    <br />
                    to your destination with MapQuest!
                </h1>
                <Steps
                    stepsStyle={css`
                        display: flex;
                        width: 100%;
                        margin-bottom: 0.5rem;
                        p {
                            text-align: left;
                        }
                        .step {
                            color: #4c8bf5;
                            font-weight: 700;
                            margin-right: 1.5rem;
                        }
                    `}
                    description={['Click "Continue"', `Click "Add to ${currentBrowserName()}"`, 'Enjoy MapQuest!']}
                />
                <Button
                    text="CONTINUE"
                    ariaLabel="CONTINUE"
                    link={chromeExtensionLink}
                    onClick={onCtaClick}
                    overrideButtonStyles={`
                        background-color: #4c8bf5;
                        color: #fff;
                        &:hover {
                            background-color: #4c8bce;
                        }

                    `}
                />
                <TermsOfService
                    textStyles={`
                    font-size: 0.7rem;
                    a {
                        text-decoration: underline;
                    }`}
                />
            </Modal>
            <FooterLight />
        </>
    );
};

export default IndexBlueCta;
